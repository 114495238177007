<template>
  <div>
    <v-card :loading="loading">
      <v-toolbar flat>
        <v-icon> fas fa-truck</v-icon>
        <v-toolbar-title class="pl-4">Depara Transportadora</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-app-bar-nav-icon @click="fechar">
          <v-icon>fas fa-times</v-icon>
        </v-app-bar-nav-icon>
      </v-toolbar>
      <v-divider></v-divider>
      <v-card-text>
        <v-row no-gutters>
          <v-col :cols="12">
            <v-btn
              color="#018656" dark
              @click="dialog=true"
            >Adicionar</v-btn>
          </v-col>
          <v-col xs="12" sm="12" md="10" lg="5" xl="5">
            <v-text-field class="mt-6" v-model="searchQuery" label="Buscar" dense outlined></v-text-field>
          </v-col>
          <v-col :cols="12">
            <v-data-table
              :headers="headers"
              hide-default-footer
              :items="filteredData"
              :items-per-page="1000000"
              class="elevation-1"
            >
            <template #item.expedicao="{ item }">
              <v-icon small color="#018454" v-if="item.expedicao">
                fas fa-check
              </v-icon>
              <v-icon v-else small color="#FF0000">
                fas fa-times
              </v-icon>
            </template>
            
            <template #item.pausarEmbalamento="{ item }">
              <v-icon small color="success" v-if="item.pausarEmbalamento">
                fas fa-check
              </v-icon>
              <v-icon small v-else color="error">
                fas fa-times
              </v-icon>
            </template>
            
            <template #item.naoEnviaCusto="{ item }">
              <v-icon small color="success" v-if="item.naoEnviaCusto">
                fas fa-check
              </v-icon>
              <v-icon small v-else color="error">
                fas fa-times
              </v-icon>
            </template>
            
            <template #item.status="{ item }">
              <v-icon small color="success" v-if="item.ignorarIntegracao">
                fas fa-check
              </v-icon>
              <v-icon small v-else color="error">
                fas fa-times
              </v-icon>
            </template>

            <template #item.actions="{ item }">
              <v-icon style="margin-left: 8px;" color="primary" @click="editItem(item)" size="small" class="me-2">fas fa-pen</v-icon>
            </template>
          </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
      <form-transportadora :visibleDialog="dialog" :dados="transportadora" @close="handleClose" @reload="carregarDados()"></form-transportadora>
    </v-card>
  </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment'
import { baseApiUrl, showError } from "@/global";
import formTransportadora from '../components/integracao/formTransportadora.vue';
export default {
  data() {
    return {
      dados: [],
      dialog: false,
      transportadora: {},
      loading: false,
      codigo_empresa: '',
      searchQuery: '',
      headers: [
        { text: "Código Transportadora", value: "codigoSisfrete", sortable: false },
        { text: "Base", value: "base", sortable: false },
        { text: "Transportadora", value: "nome", sortable: false },
        { text: "Serviço de Entrega", value: "servicoEntrega", sortable: false },
        { text: "Código Serviço", value: "codigoServico", sortable: false },
        { text: "Expedição", value: "expedicao", sortable: false },
        { text: "Pausar Embalamento", value: "pausarEmbalamento", sortable: false },
        { text: "Não Envia Custo", value: "naoEnviaCusto", sortable: false },
        { text: "Ignorar Integração", value: "status", sortable: false },
        { value: "actions", sortable: false },
      ]
    }
  },

  components: {
    formTransportadora
  },

  computed: {
    filteredData() {
      if (this.dados) {
        if (!this.searchQuery) {
          return this.dados
        }

        const query = this.searchQuery.toLowerCase()
        return this.dados.filter(item => {
          return (
            (item.nome?.toLowerCase().includes(query) ?? false) ||
            (item.servicoEntrega?.toLowerCase().includes(query) ?? false) ||
            (item.base?.toLowerCase().includes(query) ?? false)
          )
        })
      } else {
        return []
      }
    }
  },

  methods: {
    formatData(val) {
      return val ? moment(val).format("DD/MM/YYYY") : ""
    },

    handleClose() {
      this.transportadora = {}
      this.dialog = false
    },

    editItem(item) {
      this.transportadora = { ...item }
      this.dialog = true
    },

    fechar() {
      this.$router.push("/")
    },

    carregarDados() {
      this.loading = true
      axios.get(`${baseApiUrl}/transportadora-de-para`)
        .then(res => {
          this.dados = [...res.data.transportadora]
          this.loading = false
        })
        .catch(() => {
          showError('Erro ao carregar os dados!!')
          this.loading = false
        })
    }
  },

  mounted() {
    this.codigo_empresa = Number(this.$store.state.cod_empresa)
    this.carregarDados()
  }
}
</script>

<style scoped></style>
