var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"loading":_vm.loading}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-icon',[_vm._v(" fas fa-truck")]),_c('v-toolbar-title',{staticClass:"pl-4"},[_vm._v("Depara Transportadora")]),_c('v-spacer'),_c('v-app-bar-nav-icon',{on:{"click":_vm.fechar}},[_c('v-icon',[_vm._v("fas fa-times")])],1)],1),_c('v-divider'),_c('v-card-text',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":12}},[_c('v-btn',{attrs:{"color":"#018656","dark":""},on:{"click":function($event){_vm.dialog=true}}},[_vm._v("Adicionar")])],1),_c('v-col',{attrs:{"xs":"12","sm":"12","md":"10","lg":"5","xl":"5"}},[_c('v-text-field',{staticClass:"mt-6",attrs:{"label":"Buscar","dense":"","outlined":""},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1),_c('v-col',{attrs:{"cols":12}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"hide-default-footer":"","items":_vm.filteredData,"items-per-page":1000000},scopedSlots:_vm._u([{key:"item.expedicao",fn:function(ref){
var item = ref.item;
return [(item.expedicao)?_c('v-icon',{attrs:{"small":"","color":"#018454"}},[_vm._v(" fas fa-check ")]):_c('v-icon',{attrs:{"small":"","color":"#FF0000"}},[_vm._v(" fas fa-times ")])]}},{key:"item.pausarEmbalamento",fn:function(ref){
var item = ref.item;
return [(item.pausarEmbalamento)?_c('v-icon',{attrs:{"small":"","color":"success"}},[_vm._v(" fas fa-check ")]):_c('v-icon',{attrs:{"small":"","color":"error"}},[_vm._v(" fas fa-times ")])]}},{key:"item.naoEnviaCusto",fn:function(ref){
var item = ref.item;
return [(item.naoEnviaCusto)?_c('v-icon',{attrs:{"small":"","color":"success"}},[_vm._v(" fas fa-check ")]):_c('v-icon',{attrs:{"small":"","color":"error"}},[_vm._v(" fas fa-times ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.ignorarIntegracao)?_c('v-icon',{attrs:{"small":"","color":"success"}},[_vm._v(" fas fa-check ")]):_c('v-icon',{attrs:{"small":"","color":"error"}},[_vm._v(" fas fa-times ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"me-2",staticStyle:{"margin-left":"8px"},attrs:{"color":"primary","size":"small"},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("fas fa-pen")])]}}])})],1)],1)],1),_c('form-transportadora',{attrs:{"visibleDialog":_vm.dialog,"dados":_vm.transportadora},on:{"close":_vm.handleClose,"reload":function($event){return _vm.carregarDados()}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }